/**
 *
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'auth/login', ]).data(data),
    loginPin: (userId, data) => $app.api.post([ 'auth/login/users*/pin', userId, ]).data(data),
    register: (iin, phone, data) => $app.api.post([ 'auth/register**', iin, phone, ]).data(data),
    recover: (iin, data) => $app.api.post([ 'auth/recover*', iin, ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
    checkData: (data) => $app.api.post([ 'auth/check-data', ]).data(data),
    sendCode: (iin, phone) => $app.api.post([ 'sms/send-code**', iin, phone, ]),
    checkCode: (iin, data) => $app.api.post([ 'sms/check-code*', iin, ]).data(data),
    deleteAcc: (id) => $app.api.patch([ 'auth/delete/users*', id, ]),
    checkIin: (iin) => $app.api.post([ 'iin-check*', iin, ]),
    sendGuidData: (guidData) => $app.api.post('auth/uuid-data').data(guidData),
  }

  promotions = {
    get: (id) => $app.api.get([ 'promotions*', id, ]),
    getUsers: () => $app.api.get([ 'promotions/users', ]),
    edit: (id, data) => $app.api.patch([ 'promotions*', id, ]).data(data),
    create: (data) => $app.api.post([ 'promotions', ]).data(data),
    give: (id, data) => $app.api.post([ 'promotions*/give', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'promotions*', id, ]),
    assignToAll: (id) => $app.api.post([ 'promotions*/assign-to-all', id, ]),
    assignToCompany: (id, companyId) => $app.api.post([ 'promotions*/assign-to-company*', id, companyId, ]),
    assignToBranch: (id, data) => $app.api.post([ 'promotions*/assign-to-branch', id, ]).data(data),
    assignToUsers: (id, usersList) => $app.api.post([ 'promotions*/assign-to-users', id, ]).data(usersList),
    removeFromUser: (id, userId) => $app.api.post([ 'promotions*/users*', id, userId, ]),
  }

  settings = {
    get: (id) => $app.api.get([ 'settings*', id, ]),
    edit: (id, data) => $app.api.patch([ 'settings*', id, ]).data(data),
  }

  stats = {
    get: (query) => $app.api.get([ 'prosper/stats*', query, ]),
  }

  companies = {
    get: (id) => $app.api.get([ 'companies*', id, ]),
    getSlim: (id) => $app.api.get([ 'companies/slim', id, ]),
    getUserData: (id) => $app.api.get([ 'companies/user-data*', id, ]),
    getStats: () => $app.api.get([ 'companies/stats', ]),
    refresh: () => $app.api.get([ 'companies/refresh', ]),
    excel: (id, data) => $app.api.post([ 'companies*/excel', id, ]).data(data),
    getGeneralTransactionsReport: (data) => $app.api.post([ 'companies/transactions-report', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'companies*', id, ]).data(data),
    pitech: (id, data) => $app.api.post([ 'companies*/pitech', id, ]).data(data),
    payoutExcel: (id) => $app.api.get([ 'companies*/payout/excel', id, ]),
    usersExcel: (id) => $app.api.get([ 'companies*/users/excel', id, ]),
    create: (data) => $app.api.post([ 'companies', ]).data(data),
  }
  integration= {
    postFile: (companyId, file) => $app.api.post([ '/prosper/integration**/post-excel', companyId, ])
      .data(file)
      .config({
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    history: (companyId) => $app.api.get([ '/prosper/integration-files**/history', companyId, ]),
    getTemplate: () => $app.api.get([ '/prosper/integration-files/template', ]),
  }
  
  visits = {
    get: (id) => $app.api.get([ 'companies*/visits', id, ]),
  }
  commissions = {
    get: (id) => $app.api.get([ 'commissions', id, ]),
    getParentUnit: (companyId) => $app.api.get([ 'commissions*/all/parent-unit', companyId, ]),
    getByCompany: (companyId) => $app.api.get([ 'commissions*/all', companyId, ]),
    getCalculatorData: (companyId, sum) => $app.api.get([ 'commissions/calculate*', companyId, sum ]),
    create: (companyId, data) => $app.api.post([ 'commissions*', companyId, ]).data(data),
    remove: (id) => $app.api.delete([ 'commissions*', id, ]),
  }

  limits = {
    get: (id) => $app.api.get([ 'limits', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'limits*/all', companyId, ]),
    create: (companyId, data) => $app.api.post([ 'limits*/create', companyId, ]).data(data),
    remove: (id) => $app.api.post([ 'limits/delete*', id, ]),
  }

  lang = {
    locales: (lang) => $app.api.get([ 'locales', ]),
    items: () => $app.api.get([ 'translations/content', ]),
  }

  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    getCompany: (id) => $app.api.get([ 'users-companies*', id, ]),
    getByCompany: (companyId) => $app.api.get([ 'companies*/users', companyId, ]),
    edit: (id, data) => $app.api.patch([ 'users*', id, ]).data(data),
    editPassword: (id, data) => $app.api.patch([ 'users*/password', id, ]).data(data),
    sendCode: (id) => $app.api.post([ 'users*/send-code', id, ]),
    checkCode: (id, data) => $app.api.post([ 'users*/check-code', id, ]).data(data),
    search: (data, request) => $app.api.post([ 'users*/search', request, ]).data(data),
    editPin: (id, data) => $app.api.patch([ 'users*/edit-pin', id, ]).data(data),
    editLimit: (id, data) => $app.api.patch([ 'users*/limit', id, ]).data(data),
    getFree: (companyId) => $app.api.get([ 'companies*/users/free', companyId, ]),
    recover: (userId) => $app.api.patch([ 'users*/recover', userId, ]),
  }
  
  branches = {
    get: (companyId, id) => $app.api.get([ 'companies*/branches*', companyId, id, ]),
    getSlim: (companyId, id) => $app.api.get([ 'companies*/branches/slim', companyId, id, ]),
    getAllForSelect: (companyId) => $app.api.get([ 'companies*/branches-for-select', companyId, ]),
    getUsersList: (companyId, id) => $app.api.get([ 'companies*/branches*/users', companyId, id, ]),
    create: (companyId, data) => $app.api.post([ 'companies*/branches', companyId, ]).data(data),
    edit: (companyId, id, data) => $app.api.patch([ 'companies*/branches*', companyId, id, ]).data(data),
    addUsers: (companyId, id, data) => $app.api.post([ 'companies*/branches*/add', companyId, id, ]).data(data),
    removeUser: (companyId, id, userId) => $app.api.post([ 'companies*/branches*/users*/remove', companyId, id, userId, ]),
    delete: (companyId, id) => $app.api.delete([ 'companies*/branches*', companyId, id, ]),
  }

  notifications = {
    get: (id) => $app.api.get([ 'notifications*', id, ]),
    getUsers: () => $app.api.get([ 'users-companies*' ]),
    getUsersWithoutCompany: () => $app.api.get([ 'notifications/users', ]),
    send: (data) => $app.api.post([ 'notifications', ]).data(data),
    notifyAllUsers: (data) => $app.api.post([ 'notifications/notify-all', ]).data(data),
  }

  accessBlocks = {
    getAll: () => $app.api.get([ 'accessBlock*', ]),
    edit: (id, data) => $app.api.patch([ 'accessBlock/updateEndTime*', id, ]).data(data),
    save: (data) => $app.api.post([ 'accessBlock', ]).data(data),
    stop: (id) => $app.api.patch([ 'accessBlock/stop*', id, ]),
  }

  mailing = {
    get: () => $app.api.get( [ 'mailing/users/all', ] ),
    add: (data) => $app.api.post( [ 'mailing/users/add', ] ).data(data),
    delete: (id) => $app.api.patch( [ 'mailing/users/delete*', id, ] ),
  }

  transactions = {
    get: (companyId) => $app.api.get([ 'companies*/transactions', companyId, ]),
    check: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions/check', companyId, userId, ]).data(data),
    create: (companyId, userId, data) => $app.api.post([ 'companies*/users*/transactions', companyId, userId, ]).data(data),
    getList: (companyId, userId, request = '') => $app.api.get([ 'companies*/users*/transactions'+request, companyId, userId, ]),
    getAllByStatus: (request = '') => $app.api.get([ '/transactions'+ request ]),
    updateStatus: (id, data) => $app.api.post([ '/transaction**/update-status', id, ]).data(data),
    accept: (userId, transactionUuid)=>$app.api.get([ 'transaction/admin*/accept*', userId, transactionUuid ]),
    reject: (userId, transactionUuid)=>$app.api.get([ 'transaction/admin*/reject*', userId, transactionUuid ]),
  }

  contacts = {
    get: () => $app.api.get([ 'contacts', ]),
  }

  application = {
    getByUser: (userId) => $app.api.get([ 'applications/users*', userId, ]),
    get: (id) => $app.api.get([ 'applications', id, ]),
    create: (data) => $app.api.post([ 'applications', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'applications*', id, ]).data(data),
  }

  faqs = {
    get: (id) => $app.api.get([ 'faqs*', id, ]),
    create: (data) => $app.api.post([ 'faqs', ]).data(data),
    edit: (id, data) => $app.api.patch([ 'faqs*', id, ]).data(data),
  }

  logs = {
    get: () => $app.api.get([ 'logs', ]),
  }

  filials = {
    get: (id) => $app.api.get([ 'companies*/filials', id, ]),
    create: (id, data) => $app.api.post([ 'companies*/filials', id, ]).data(data),
    delete: (id) => $app.api.delete([ 'filials*', id, ]),
    getById: (id) => $app.api.get([ 'filials*', id, ]),
    getUsers: (id) => $app.api.get([ 'filials*/users', id ]),
    getFreeUsers: (id) => $app.api.get([ 'filials*/users/free', id ]),
    addUsers: (id, data) => $app.api.post([ 'filials*/users', id ]).data(data),
    removeUser: (id, userId) => $app.api.delete([ 'filials*/users*', id, userId ]),
    edit: (id, data) => $app.api.put([ 'filials*', id ]).data(data),
  }
  antifraud={
    all: () => $app.api.get([ 'transaction-requests/all' ]),
    history: () => $app.api.get([ 'transaction-requests/history' ]),
    actual: () => $app.api.get([ 'transaction-requests/actual' ]),

  }

}
